import { createAction, props } from '@ngrx/store';

import { Datasheet, DatasheetDetail, ProtocolType } from '@priva/next-model';

export const getAllDatasheets = createAction('[Datasheet API] Get all datasheets');
export const getAllDatasheetsSuccess = createAction(
    '[Datasheet API] Get all datasheets (success)',
    props<{ datasheets: Datasheet[] }>(),
);
export const getProtocolDatasheets = createAction(
    '[Datasheet API] Get all datasheets of protocol',
    props<{ protocol: ProtocolType }>(),
);

export const getDatasheetDetail = createAction(
    '[Datasheet API] Get Datasheet detail',
    props<{ datasheetId: string; protocolType: ProtocolType }>(),
);

export const getDatasheetDetailSuccess = createAction(
    '[Datasheet API] Get Datasheet detail (success)',
    props<{ datasheet: DatasheetDetail }>(),
);

export const createDatasheet = createAction(
    '[Datasheet API] Create Datasheet',
    props<{ datasheet: Datasheet }>(),
);

export const createDatasheetSuccess = createAction(
    '[Datasheet API] Create Datasheet (success)',
    props<{ datasheet: Datasheet }>(),
);

export const updateDatasheet = createAction(
    '[Datasheet API] Update Datasheet',
    props<{ datasheet: Datasheet }>(),
);

export const updateDatasheetSuccess = createAction(
    '[Datasheet API] Update Datasheet (success)',
    props<{ datasheet: Datasheet }>(),
);

export const deleteDatasheet = createAction(
    '[Datasheet API] Delete Datasheet',
    props<{ datasheetId: string }>(),
);

export const deleteDatasheetSuccess = createAction(
    '[Datasheet API] delete DatasheetSuccess (success)',
    props<{ datasheetId: string }>(),
);
